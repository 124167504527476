import { IconProps } from "@phiaco/phia-ui";

const ThredUp: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="117"
      height="28"
      viewBox="0 0 117 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M44.2132 16.4589C46.974 15.9286 48.8145 14.6268 48.7176 10.6732C48.5723 5.46607 45.3272 4.45357 39.4667 4.45357H34.2842V27.5H39.3698V18.2911H40.5807C42.3243 18.2911 43.0508 19.2071 43.1477 21.425C43.293 24.2214 43.3899 26.6321 43.7289 27.5H49.0082C48.4755 26.4393 48.427 24.4625 48.2817 21.3768C48.0396 17.4714 47.2646 17.0375 44.2132 16.4589ZM40.2901 14.3857H39.3698V8.31071H40.2901C42.9055 8.31071 43.6805 9.32321 43.6805 11.2518C43.6805 13.9518 42.7602 14.3857 40.2901 14.3857ZM0.186523 8.31071H4.20656V27.5H9.29216V8.31071H13.4091V4.40536H0.186523V8.31071ZM25.3723 13.9036H20.7711V4.40536H15.6855V27.5H20.7711V17.8089H25.3723V27.5H30.8454V4.40536H25.3723V13.9036ZM56.5155 17.6161H61.3105V13.7107H56.5155V8.31071H62.7636V4.40536H51.43V27.5H62.7636V23.5946H56.5155V17.6161ZM70.6099 4.40536H65.5727V27.5H70.6583C78.1656 27.5 80.4905 23.3536 80.4905 16.2179V15.7357C80.4421 7.97321 78.2625 4.40536 70.6099 4.40536ZM71.6755 23.4982H70.6583V8.40714H71.6755C74.8237 8.40714 75.5018 10.8179 75.5018 15.7839C75.5018 20.7018 74.8237 23.4982 71.6755 23.4982ZM107.371 0.5H101.463V23.5946H106.597V16.025H107.371C113.426 16.025 116.187 13.3732 116.187 8.2625C116.187 3.29643 113.329 0.5 107.371 0.5ZM107.275 12.1196H106.548V4.35714H107.275C109.939 4.35714 111.198 5.36964 111.198 8.2625C111.198 11.1554 109.939 12.1196 107.275 12.1196ZM93.1803 15.8804C93.1803 18.4839 92.8412 19.7375 90.9039 19.7375C88.9665 19.7375 88.6274 18.4839 88.6274 15.8804V0.5H83.3481V15.2536C83.3481 21.0875 84.8011 23.9321 90.9039 23.9321C97.0066 23.9321 98.4596 21.0875 98.4596 15.2536V0.5H93.1803V15.8804Z"
        fill="black"
      />
    </svg>
  );
};

export default ThredUp;
