import { useSearch } from "@/context/SearchContext";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import SearchOverlayV2 from "./SearchOverlayV2";
import { overlayVariants } from "./utils";

interface SearchOverlayContainerProps {
  className?: string;
  shouldHide?: boolean;
}

const SearchOverlayContainer: React.FC<SearchOverlayContainerProps> = ({
  className,
  shouldHide = false,
}) => {
  const { isSearchOverlayVisible, hideSearchOverlay } = useSearch();
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        hideSearchOverlay();
      }
    };

    if (isSearchOverlayVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOverlayVisible, hideSearchOverlay]);

  return (
    <AnimatePresence>
      {isSearchOverlayVisible && !shouldHide && (
        // Had to add this extra div so search overlay comes behind search bar
        <div className="w-full overflow-hidden">
          <motion.div
            ref={overlayRef}
            key="searchOverlay"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={cn("w-full rounded-b-[22px] bg-white", className)}>
            <SearchOverlayV2 />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SearchOverlayContainer;
