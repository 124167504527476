import Grailed from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/Grailed";
import Poshmark from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/Poshmark";
import Rebag from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/Rebag";
import StockX from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/StockX";
import TheRealReal from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/TheRealReal";
import ThredUp from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/ThredUp";
import Vestiaire from "@/app/_components/HeroBannerV2/ResaleCompanyIcons/Vestiaire";
import { ProductSearchParams } from "@/app/products/_types";
import { routes } from "@/routes";

const leftProduct = {
  productUrl:
    "https://www.neimanmarcus.com/p/ferragamo-mens-reversible-leather-gancini-buckle-belt-prod189220144",
  title: "Men's Reversible Leather Gancini-Buckle Belt",
  image:
    "https://media.neimanmarcus.com/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_790/01/nm_1718360_100106_m",
  originalPrice: 595,
  savePercent: 75,
  website: "neimanmarcus.com",
};

const middleProductOriginal = {
  productUrl:
    "https://www.cartier.com/en-us/watches/collections/tank/tank-louis-cartier-watch--CRWGTA0011.html?adlsid=c|g|ID_WGTA0011|68306333455",
  title: "Tank Louis Cartier watch",
  image:
    "https://www.cartier.com/dw/image/v2/BGTJ_PRD/on/demandware.static/-/Sites-cartier-master/default/dw1fe1d63b/images/large/e39f7697923d53ec8edcd3d44844a8a5.png",
  originalPrice: 13000,
  website: "cartier.com",
};

const middleProductResale = {
  productUrl:
    "https://www.cartier.com/en-us/watches/collections/tank/tank-louis-cartier-watch--CRWGTA0011.html?adlsid=c|g|ID_WGTA0011|68306333455",
  title: "Tank Louis Cartier watch",
  image: "https://product-images.therealreal.com/CRT115641_1_enlarged.jpg",
  originalPrice: 13000,
  savePercent: 83.88,
  website: "cartier.com",
};

const rightProduct = {
  productUrl:
    "https://madisonavenuecouture.com/products/the-row-soft-margaux-15-bag-black-saddle-gold-hardware-1",
  title: "The Row Soft Margaux 15 Bag Black Saddle Gold Hardware",
  image:
    "https://madisonavenuecouture.com/cdn/shop/files/TR-OT-240906-2-01.jpg",
  originalPrice: 7250,
  savePercent: 35,
  website: "madisonavenuecouture.com",
};

interface Product {
  title: string;
  image: string;
  productUrl: string;
  originalPrice: number;
  website: string;
  source: string;
}

// Function to build a product route link with scraped query params
export const buildProductLink = (product: Product) => {
  const linkPastingParams = `${ProductSearchParams.SCRAPED_NAME}=${product.title}&${ProductSearchParams.SCRAPED_IMAGE_URL}=${product.image}&${ProductSearchParams.SCRAPED_PRICE}=${product.originalPrice}&sb=${product.website?.replace(/\.[^.]+$/, "")}&${ProductSearchParams.SOURCE}=${product.source}`;
  return `${routes.PRODUCTS}?${ProductSearchParams.LINK}=${product.productUrl}&${ProductSearchParams.CUSTOM_PRICE_RANGE}=true&${linkPastingParams}`;
};

// Resale company logos
export const logoData = [
  { id: "vestiaire", Icon: Vestiaire },
  { id: "thredup", Icon: ThredUp },
  { id: "poshmark", Icon: Poshmark },
  { id: "therealreal", Icon: TheRealReal },
  { id: "rebag", Icon: Rebag },
  { id: "grailed", Icon: Grailed },
  { id: "stockx", Icon: StockX },
];

// TODO: Replace this once the ShowcaseProducts component is dynamic
export const showcasedData = {
  leftProduct,
  middleProductOriginal,
  middleProductResale,
  rightProduct,
};
