import { IconProps } from "@phiaco/phia-ui";

const Poshmark: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="151"
      height="28"
      viewBox="0 0 151 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M15.9952 0.828613C11.8539 0.828613 8.48474 4.2072 8.48474 8.36005V20.1974H11.2644L11.2636 8.36005C11.2636 5.74404 13.3863 3.61557 15.9952 3.61557C18.6038 3.61557 20.7269 5.74404 20.7269 8.36005C20.7269 10.8831 18.7524 12.9523 16.2719 13.0964V15.8861C20.2852 15.7398 23.5053 12.4198 23.5053 8.36005C23.5053 4.2072 20.1364 0.828613 15.9952 0.828613Z"
          fill="black"
        />
        <path
          d="M12.4285 19.6404C12.4285 22.2563 10.3055 24.3843 7.69627 24.3843C5.08798 24.3843 2.96506 22.2563 2.96506 19.6404C2.96506 17.1171 4.9395 15.0478 7.41971 14.9038V12.1138C3.40668 12.2603 0.186523 15.5803 0.186523 19.6404C0.186523 23.7927 3.55551 27.1714 7.69627 27.1714C11.8376 27.1714 15.2068 23.7927 15.2068 19.6404V7.81376L12.4279 7.81286L12.4285 19.6404Z"
          fill="black"
        />
        <path
          d="M37.6217 14.5937H34.1674V8.69625H37.7015C39.9579 8.69625 41.4555 9.69184 41.4555 11.6257C41.4555 13.4065 39.9378 14.5937 37.6217 14.5937ZM37.8213 7.29817H32.59V20.7018H34.1674V15.9723H37.5619C40.5368 15.9723 43.0526 14.4787 43.0526 11.5682C43.0526 8.90677 40.9761 7.29817 37.8213 7.29817Z"
          fill="black"
        />
        <path
          d="M52.456 19.5338C49.2612 19.5338 46.945 17.0447 46.945 13.9808C46.945 10.9174 49.2211 8.46643 52.4159 8.46643C55.6108 8.46643 57.9269 10.9557 57.9269 14.0192C57.9269 17.083 55.6508 19.5338 52.456 19.5338ZM52.456 7.06847C48.2029 7.06847 45.3078 10.3045 45.3078 14.0192C45.3078 17.7337 48.1631 20.9316 52.4159 20.9316C56.669 20.9316 59.5641 17.6955 59.5641 13.9808C59.5641 10.2662 56.7091 7.06847 52.456 7.06847Z"
          fill="black"
        />
        <path
          d="M67.8111 13.2725C64.7362 12.6406 64.0174 11.932 64.0174 10.649C64.0174 9.42359 65.1755 8.46643 66.9926 8.46643C68.4301 8.46643 69.7281 8.90677 71.026 9.92166L71.9444 8.75343C70.5269 7.66201 69.0691 7.107 67.0326 7.107C64.3769 7.107 62.44 8.63875 62.44 10.7834C62.44 13.0045 63.9176 14.0002 67.1324 14.6702C70.0676 15.2639 70.7663 15.9723 70.7663 17.236C70.7663 18.5954 69.5284 19.5338 67.6516 19.5338C65.7146 19.5338 64.3172 18.902 62.8593 17.6384L61.8811 18.7487C63.5584 20.1847 65.3751 20.8934 67.5915 20.8934C70.367 20.8934 72.3637 19.3998 72.3637 17.083C72.3637 15.0149 70.9262 13.9233 67.8111 13.2725Z"
          fill="black"
        />
        <path
          d="M85.2213 13.2533H77.1545V7.29817H75.5771V20.7018H77.1545V14.6702H85.2213V20.7018H86.7987V7.29817H85.2213V13.2533Z"
          fill="black"
        />
        <path
          d="M97.4394 14.6127L92.348 7.29817H90.7506V20.7018H92.2883V9.86416L97.3797 17.0254H97.4597L102.551 9.84496V20.7018H104.129V7.29817H102.531L97.4394 14.6127Z"
          fill="black"
        />
        <path
          d="M110.776 15.7999L113.971 8.96427L117.145 15.7999H110.776ZM113.252 7.20247L106.883 20.7018H108.5L110.157 17.1595H117.764L119.402 20.7018H121.099L114.729 7.20247H113.252Z"
          fill="black"
        />
        <path
          d="M125.43 14.1342V8.69625H129.743C132 8.69625 133.317 9.69184 133.317 11.3577C133.317 13.1 131.82 14.1342 129.723 14.1342H125.43ZM134.915 11.3002C134.915 8.88745 132.938 7.29817 129.863 7.29817H123.853V20.7018H125.43V15.4936H129.424L133.477 20.7018H135.414L131.121 15.2256C133.317 14.8426 134.915 13.5405 134.915 11.3002Z"
          fill="black"
        />
        <path
          d="M143.938 13.1575L149.929 7.29817H147.892L139.865 15.3021V7.29817H138.288V20.7018H139.865V17.102L142.84 14.2107L148.211 20.7018H150.188L143.938 13.1575Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default Poshmark;
