import { IconProps } from "@phiaco/phia-ui";

const Vestiaire: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="227"
      height="20"
      viewBox="0 0 227 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M219.608 7.26983C219.841 7.04746 220.178 6.87451 220.489 6.87451C221.603 6.87451 222.173 8.72757 222.173 10.5806C221.862 10.6547 220.826 10.803 218.417 10.8771C218.494 9.59233 218.831 8.13459 219.608 7.26983ZM226.11 15.3739C225.307 16.0657 224.271 16.6092 222.82 16.6092C220.023 16.6092 218.417 14.7562 218.417 11.2971H225.928C226.006 11.1489 226.006 10.9018 226.006 10.4571C226.006 8.23342 224.167 6.38036 220.722 6.38036C217.122 6.38036 214.091 9.22172 214.091 13.3231C214.091 16.7081 216.422 19.4753 220.1 19.4753C223.053 19.4753 224.97 17.8693 226.188 15.4727L226.11 15.3739Z"
          fill="black"
        />
        <path
          d="M215.619 6.97334V6.89922H210.672V6.97334L211.319 7.36866C211.967 7.66515 212.356 8.06047 212.356 8.38166C212.356 8.85111 211.708 10.8524 211.319 12.0137L210.283 14.7809H210.206L207.797 8.52991C207.641 8.23342 207.641 7.8381 207.641 7.76398C207.641 7.54161 207.797 7.46749 208.444 7.29454L209.169 7.14629V6.92393H201.01V7.14629L201.502 7.29454C202.228 7.44278 202.383 7.51691 202.772 8.45579L207.719 19.2282H208.91L213.314 9.44408C213.884 8.20871 214.195 7.98635 214.92 7.44278L215.619 6.97334Z"
          fill="black"
        />
        <path
          d="M199.456 18.1411V8.65345C199.456 8.43108 199.456 7.26983 199.534 6.25683L199.456 6.1827L193.706 8.03576V8.10988L194.509 9.02406C194.742 9.32055 194.742 9.56762 194.742 10.0371V18.1905C194.742 18.5858 194.664 18.6599 194.016 18.8082L193.628 18.8823V19.1047H200.673V18.8823L200.285 18.8082C199.456 18.6105 199.456 18.5117 199.456 18.1411Z"
          fill="black"
        />
        <path
          d="M197.073 5.34265C198.601 5.34265 199.715 4.25553 199.715 3.02016C199.715 1.78479 198.679 0.796489 197.306 0.796489C195.7 0.796489 194.509 1.95774 194.509 3.11899C194.509 4.35436 195.7 5.34265 197.073 5.34265Z"
          fill="black"
        />
        <path
          d="M191.064 16.9057C190.494 16.9057 189.95 16.8316 189.613 16.5104C189.302 16.2139 189.121 15.7445 189.121 15.0527V8.90052H192.721L193.032 6.89922H189.121L189.199 3.66255H189.121C187.515 5.04616 185.21 6.89922 182.801 8.60403V8.90052H184.407V15.7445C184.407 17.054 184.64 17.894 185.365 18.5858C186.013 19.2035 186.971 19.5 188.318 19.5C190.805 19.5 192.385 18.1164 193.343 16.3375L193.265 16.2633C192.592 16.6834 191.789 16.9057 191.064 16.9057Z"
          fill="black"
        />
        <path
          d="M176.843 6.97334C177.076 6.97334 177.154 7.04746 177.335 7.36866L179.097 10.4571C179.485 11.1489 179.744 11.223 180.133 11.223C181.091 11.223 182.283 10.4571 182.283 9.14759C182.283 7.54161 180.366 6.45449 177.724 6.45449C173.32 6.45449 170.601 9.69116 170.601 13.2984C170.601 16.6092 172.828 19.4506 176.688 19.4506C179.174 19.4506 181.091 18.2152 182.205 15.9915L182.127 15.9174C181.402 16.6092 180.521 17.0046 179.33 17.0046C176.61 17.0046 174.849 14.7809 174.849 11.5442C174.745 8.57932 175.962 6.97334 176.843 6.97334Z"
          fill="black"
        />
        <path
          d="M162.752 7.26983C162.985 7.04746 163.322 6.87451 163.633 6.87451C164.747 6.87451 165.316 8.72757 165.316 10.5806C165.006 10.6547 163.97 10.803 161.561 10.8771C161.638 9.59233 161.975 8.13459 162.752 7.26983ZM169.228 15.3739C168.425 16.0657 167.389 16.6092 165.938 16.6092C163.141 16.6092 161.535 14.7562 161.535 11.2971H169.046C169.124 11.1489 169.124 10.9018 169.124 10.4571C169.124 8.23342 167.285 6.38036 163.84 6.38036C160.24 6.38036 157.209 9.22172 157.209 13.3231C157.209 16.7081 159.54 19.4753 163.218 19.4753C166.171 19.4753 168.088 17.8693 169.305 15.4727L169.228 15.3739Z"
          fill="black"
        />
        <path
          d="M155.655 18.1411V2.89662C155.655 2.67425 155.655 1.513 155.732 0.5H155.655L149.904 2.27893V2.35306L150.707 3.26723C150.941 3.56372 150.941 3.81079 150.941 4.28023V18.1411C150.941 18.5364 150.863 18.6105 150.215 18.7588L149.827 18.8329V19.0553H156.872V18.8329L156.484 18.7588C155.732 18.6105 155.655 18.5117 155.655 18.1411Z"
          fill="black"
        />
        <path
          d="M147.806 18.1411V2.89662C147.806 2.67425 147.806 1.513 147.884 0.5L142.134 2.27893V2.35306L142.937 3.26723C143.17 3.56372 143.17 3.81079 143.17 4.28023V18.1411C143.17 18.5364 143.092 18.6105 142.444 18.7588L142.056 18.8329V19.0553H149.101V18.8329L148.713 18.7588C147.962 18.6105 147.806 18.5117 147.806 18.1411Z"
          fill="black"
        />
        <path
          d="M136.124 18.1411C135.969 18.5364 135.632 18.9811 135.01 18.9811C133.56 18.9811 132.783 15.8186 132.783 12.5078C132.783 10.9018 132.861 8.97464 133.508 7.81339C133.663 7.41808 134 6.97334 134.622 6.97334C136.072 6.97334 136.85 10.1359 136.85 13.4467C136.85 15.0527 136.694 16.9798 136.124 18.1411ZM134.933 6.42978C132.783 6.42978 131.099 7.12159 129.726 8.43108C128.69 9.44408 127.81 11.1242 127.81 13.0514C127.81 16.3622 130.296 19.4506 134.596 19.4506C136.746 19.4506 138.43 18.7588 139.802 17.4493C140.839 16.4363 141.719 14.7562 141.719 12.829C141.719 9.59233 139.258 6.42978 134.933 6.42978Z"
          fill="black"
        />
        <path
          d="M122.37 17.5234C121.334 18.5364 120.453 18.9811 119.495 18.9811C116.62 18.9811 114.781 14.9785 114.781 9.88882C114.781 4.10728 116.853 1.56242 119.262 1.56242C120.298 1.56242 121.023 1.85891 122.059 2.87191C123.173 3.95904 124.209 5.56502 125.582 7.88752H125.815C125.737 6.10858 125.504 2.94603 125.323 1.19181H125.167L124.934 1.4883C124.779 1.71066 124.701 1.78479 124.442 1.78479C123.95 1.78479 121.412 1.09298 119.495 1.09298C112.941 1.09298 109.341 5.24382 109.341 10.4818C109.341 16.2633 113.408 19.5 119.262 19.5C120.79 19.5 123.251 19.0306 125.66 17.9681C125.815 17.6716 126.385 13.8914 126.463 12.0384L126.307 11.9642C124.779 14.5832 123.51 16.4363 122.37 17.5234Z"
          fill="black"
        />
        <path
          d="M97.9956 7.26983C98.2288 7.04746 98.5655 6.87451 98.8763 6.87451C99.9901 6.87451 100.56 8.72757 100.56 10.5806C100.249 10.6547 99.2131 10.803 96.8041 10.8771C96.8559 9.59233 97.1927 8.13459 97.9956 7.26983ZM104.471 15.3739C103.668 16.0657 102.632 16.6092 101.182 16.6092C98.3842 16.6092 96.7782 14.7562 96.7782 11.2971H104.29C104.368 11.1489 104.368 10.9018 104.368 10.4571C104.368 8.23342 102.529 6.38036 99.0835 6.38036C95.4831 6.38036 92.4525 9.22172 92.4525 13.3231C92.4525 16.7081 94.7837 19.4753 98.4619 19.4753C101.415 19.4753 103.332 17.8693 104.549 15.4727L104.471 15.3739Z"
          fill="black"
        />
        <path
          d="M88.3081 10.1359C88.8779 10.1359 89.9917 10.4324 91.1833 11.0501C91.9085 10.2841 92.3748 9.27113 92.3748 8.13459C92.3748 7.12159 91.8049 6.28153 90.6134 6.28153C89.1629 6.28153 87.6605 7.88752 86.9352 10.4324L86.7798 10.3583L86.8575 8.20871C86.8575 7.73927 86.9352 7.19571 87.0129 6.13329L86.9352 6.05917L81.1072 7.91222V7.98635L81.9101 8.90052C82.1433 9.12289 82.1433 9.44408 82.1433 9.91352V18.067C82.1433 18.4623 82.0656 18.5364 81.418 18.6847L81.0294 18.7588V18.9811H88.6189V18.7588L87.7382 18.6847C87.0129 18.6105 86.9352 18.5364 86.9352 18.067V11.2724C87.1943 10.6547 87.5828 10.1359 88.3081 10.1359Z"
          fill="black"
        />
        <path
          d="M79.3458 18.1411V8.65345C79.3458 8.43108 79.3458 7.26983 79.4235 6.25683H79.3458L73.5954 8.03576V8.10988L74.3984 9.02406C74.6315 9.32055 74.6315 9.56762 74.6315 10.0371V18.1905C74.6315 18.5858 74.5538 18.6599 73.9062 18.8082L73.5177 18.8823V19.1047H80.5632V18.8823L80.1747 18.8082C79.4235 18.6105 79.3458 18.5117 79.3458 18.1411Z"
          fill="black"
        />
        <path
          d="M76.9627 5.34265C78.491 5.34265 79.6048 4.25553 79.6048 3.02016C79.6048 1.78479 78.5687 0.796489 77.1959 0.796489C75.5899 0.796489 74.3984 1.95774 74.3984 3.11899C74.3984 4.35436 75.5899 5.34265 76.9627 5.34265Z"
          fill="black"
        />
        <path
          d="M66.8089 17.301C66.5758 17.4493 66.4204 17.5234 66.006 17.5234C65.2807 17.5234 64.659 16.8316 64.659 15.5962C64.659 14.9044 64.8144 14.435 65.2289 14.0644C65.6174 13.6691 65.9541 13.5208 66.7571 13.0514V17.301H66.8089ZM73.3623 17.4493C72.9737 17.6716 72.7147 17.7458 72.4816 17.7458C72.2485 17.7458 71.9894 17.6717 71.7563 17.5234C71.5232 17.301 71.3678 16.9798 71.3678 16.1398V10.1359C71.3678 8.90052 71.2124 7.98635 70.4871 7.29454C69.8395 6.67685 68.8034 6.38036 67.4565 6.38036C65.2289 6.38036 62.6645 7.98635 61.3694 9.22172C60.6441 9.91352 60.3333 10.4571 60.3333 10.9265C60.3333 11.5442 60.8254 11.9395 61.9392 11.9395C62.8199 11.9395 64.0892 11.643 64.659 11.3218L63.856 8.62874C64.1669 8.33225 64.5036 8.23342 64.9698 8.23342C65.462 8.23342 65.9282 8.30754 66.1614 8.62874C66.5499 8.92523 66.7312 9.78999 66.7312 11.4701V12.7796L64.5036 13.4714C62.1724 14.2373 61.3953 14.5585 60.8254 15.1762C60.4369 15.5715 60.1779 16.0904 60.1779 16.6339C60.1779 18.0176 61.214 19.327 63.0531 19.327C64.3223 19.327 65.1253 18.8576 66.3427 17.8693L66.7312 17.5728H66.8089C67.3011 18.7341 68.3372 19.4259 69.7618 19.4259C71.6009 19.4259 72.9478 18.1905 73.44 17.4987H73.3623V17.4493Z"
          fill="black"
        />
        <path
          d="M58.3129 18.1411V8.65345C58.3129 8.43108 58.3129 7.26983 58.3906 6.25683L58.3129 6.1827L52.5625 7.96164V8.03576L53.3655 8.94993C53.5986 9.24642 53.5986 9.4935 53.5986 9.96294V18.1164C53.5986 18.5117 53.5209 18.5858 52.8733 18.7341L52.4848 18.8082V19.0306H59.608V18.8082L59.2195 18.7341C58.3129 18.6105 58.3129 18.5117 58.3129 18.1411Z"
          fill="black"
        />
        <path
          d="M55.9298 5.34265C57.4581 5.34265 58.5719 4.25553 58.5719 3.02016C58.5719 1.78479 57.5358 0.796489 56.163 0.796489C54.557 0.796489 53.3655 1.95774 53.3655 3.11899C53.3655 4.35436 54.557 5.34265 55.9298 5.34265Z"
          fill="black"
        />
        <path
          d="M49.9204 16.9057C49.3506 16.9057 48.8066 16.8316 48.4699 16.5104C48.1591 16.2139 47.9778 15.7445 47.9778 15.0527V8.90052H51.5782L51.889 6.89922H47.9778L48.1332 3.58843H48.0555C46.4495 4.97204 44.1442 6.8251 41.7352 8.52991V8.8264H43.3412V15.6704C43.3412 16.9798 43.5743 17.8199 44.2996 18.5117C44.9472 19.1294 45.9056 19.4259 47.2525 19.4259C49.7391 19.4259 51.3192 18.0423 52.2776 16.2633L52.1999 16.1892C51.4487 16.6834 50.6457 16.9057 49.9204 16.9057Z"
          fill="black"
        />
        <path
          d="M36.8137 10.6547C35.5445 9.96294 34.7415 9.34525 34.7415 8.33225C34.7415 7.64044 35.2337 7.02276 36.1921 7.02276C37.1505 7.02276 37.9535 7.4922 40.4401 10.803L40.5955 10.7289C40.5955 9.96294 40.5178 7.71456 40.4401 7.19571C39.5594 6.89922 38.1089 6.42978 36.3734 6.42978C33.3428 6.42978 31.2447 8.13459 31.2447 10.2841C31.2447 12.6808 33.1615 13.5949 35.156 14.7562C36.8396 15.7692 37.7203 16.461 37.7203 17.4493C37.7203 18.2152 37.2282 18.8329 36.2698 18.8329C35.0006 18.8329 33.6277 17.5234 31.0634 14.2867L30.908 14.3609C30.908 15.5962 31.1411 17.5975 31.2965 18.5858C32.488 19.0553 34.1717 19.3518 35.6999 19.3518C38.9896 19.3518 41.2172 17.8199 41.2172 15.2009C41.2172 13.0514 39.2227 11.8901 36.8137 10.6547Z"
          fill="black"
        />
        <path
          d="M23.0595 7.26983C23.2926 7.04746 23.6293 6.87451 23.9402 6.87451C25.054 6.87451 25.6238 8.72757 25.6238 10.5806C25.313 10.6547 24.2769 10.803 21.868 10.8771C21.9457 9.59233 22.2565 8.13459 23.0595 7.26983ZM29.5351 15.3739C28.7321 16.0657 27.696 16.6092 26.2455 16.6092C23.448 16.6092 21.8421 14.7562 21.8421 11.2971H29.3538C29.4315 11.1489 29.4315 10.9018 29.4315 10.4571C29.4315 8.23342 27.5924 6.38036 24.1474 6.38036C20.5728 6.35566 17.5422 9.19701 17.5422 13.2737C17.5422 16.6586 19.8735 19.4259 23.5516 19.4259C26.5045 19.4259 28.4213 17.8199 29.6387 15.4233L29.5351 15.3739Z"
          fill="black"
        />
        <path
          d="M19.6921 2.03186L20.6505 1.66125V1.513H13.4496V1.66125L14.5634 2.13069C15.6772 2.60013 15.9104 2.74837 15.9104 3.21782C15.9104 3.98374 15.1074 6.52861 14.952 7.07217L12.543 14.2373L8.73535 4.13199C8.50223 3.44018 8.42452 2.97074 8.42452 2.74837C8.42452 2.45189 8.57994 2.13069 9.53833 1.98244L10.4967 1.8342V1.513H0.1875V1.73537L0.498331 1.80949C2.10429 2.10598 2.33741 2.42718 4.02108 6.42978L9.53833 19.4506H10.9889L16.5061 6.42978C17.9308 3.26723 18.2675 2.72367 19.6921 2.03186Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export default Vestiaire;
