import { IconProps } from "@phiaco/phia-ui";

const StockX: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="104"
      height="20"
      viewBox="0 0 104 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M96.3319 13.75L103.813 20V15L97.8318 10.0028L103.813 5V0L93.3598 8.74295V6.24995L85.8782 0V5L91.8599 9.9972L85.8782 15V20L96.3319 11.2572V13.75Z"
          fill="black"
        />
        <path
          d="M11.216 8.4L7.85948 7.775C5.98903 7.425 5.16919 7 5.16919 6C5.16919 4.59998 6.78336 3.87497 8.80749 3.87497C10.9341 3.87497 12.8301 4.67497 13.2401 6.375H16.8272C16.4173 3.12501 13.4195 1 8.80749 1C4.55427 1 1.19781 2.82506 1.19781 6.12505C1.19781 9.00005 3.70875 10.4501 7.0652 11.0751L10.4217 11.7001C12.2921 12.0501 13.2401 12.625 13.2401 13.75C13.2401 15.275 11.4978 16.125 9.34554 16.125C6.70649 16.125 4.9386 14.875 4.52865 13H0.813477C1.35153 16.75 4.37492 19 9.34554 19C13.7781 19 17.2114 17.2501 17.2114 13.625C17.2115 10.425 14.5724 9.025 11.216 8.4Z"
          fill="black"
        />
        <path
          d="M25.7436 3.12501V1.25H22.0284V2.62501C22.0284 4.24999 21.2598 5 19.5943 5H18.5438V7.75H21.4903V14.5C21.4903 17.5 23.002 18.75 26.5891 18.75H29.6381V16H26.9222C25.6411 16 25.2055 15.625 25.2055 14.5V7.75H29.6381V5H24.1294L25.7436 3.12501Z"
          fill="black"
        />
        <path
          d="M57.8735 16.25C55.1832 16.25 53.4666 14.4 53.4666 11.9C53.4666 9.4 55.1832 7.55 57.8735 7.55C59.9489 7.55 61.435 8.675 61.8193 10.05H65.4832C64.817 6.8 61.9218 4.79999 57.8735 4.79999C53.031 4.79999 49.6744 7.65 49.6744 11.9C49.6744 16.15 53.031 19 57.8735 19C61.9218 19 65.0989 17 65.6113 13.625H61.9474C61.5631 14.875 60.1795 16.25 57.8735 16.25Z"
          fill="black"
        />
        <path
          d="M39.4256 4.79999C34.4294 4.79999 31.2267 7.65 31.2267 11.9C31.2267 16.15 34.4294 19 39.4256 19C44.4219 19 47.6247 16.15 47.6247 11.9C47.6247 7.65 44.4219 4.79999 39.4256 4.79999ZM39.4256 16.25C36.7353 16.25 35.0187 14.4 35.0187 11.9C35.0187 9.4 36.7353 7.55 39.4256 7.55C42.1159 7.55 43.8326 9.4 43.8326 11.9C43.8326 14.4 42.116 16.25 39.4256 16.25Z"
          fill="black"
        />
        <path
          d="M75.1939 11.4501L82.9061 5.00005H77.9098L71.6068 10.4001V1.25H67.8917V18.75H71.6068V14.4501L73.8344 12.5871L79.2421 18.75H83.9566L77.5511 11.4501H75.1939Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default StockX;
