import { Variants } from "framer-motion";

// Using variants because of 'custom' keyword support
export const overlayVariants: Variants = {
  hidden: {
    opacity: 0,
    y: "-10%", // Slight slide up for smooth exit
    height: 0, // Collapse height on exit
    transition: {
      duration: 0.3,
      ease: "easeIn", // Smooth easing for exit
    },
  },
  visible: {
    opacity: 1,
    y: "0%", // Slide in naturally
    height: "auto", // Expand to full height
    transition: {
      duration: 0.4, // Slightly slower on entry for smoothness
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: "-10%", // Same offset as hidden for consistency
    height: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

export const POPULAR_SEARCHES = [
  {
    productUrl:
      "https://www.bloomingdales.com/shop/product/celine-triomphe-oval-sunglasses-52mm?ID=3953014&pla_country=US",
    image:
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/11489589_fpx.tif?qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0&wid=1200&fmt=webp",
    title: "CELINE Triomphe Oval Sunglasses",
    price: 520,
  },
  {
    productUrl:
      "https://www.saksfifthavenue.com/product/david-yurman-cable-flex-bracelet-in-sterling-silver-0400021238296.html?dwvar_0400021238296_color=SILVER",
    image:
      "https://cdn.saksfifthavenue.com/is/image/saks/0400021238296_SILVER?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    title: "David Yurman Cable Flex Bracelet",
    price: 425,
  },
  {
    productUrl: "https://cultgaia.com/products/aiko-top-handle-off-white",
    image:
      "https://cultgaia.com/cdn/shop/products/CG_S.HS23_218_WEB_882da056-7f04-44e8-9c31-0a1f960a467f.jpg",
    title: "AIKO BAG",
    price: 278,
  },
  {
    productUrl:
      "https://www.mytheresa.com/us/en/women/ganni-faux-leather-slingback-flats-red-p00916752",
    image:
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/a7/P00916752.jpg",
    title: "Ganni Faux leather slingback flats",
    price: 425,
  },
  {
    productUrl:
      "https://www.net-a-porter.com/en-gb/shop/product/cartier/jewelry-and-watches/watches/panthere-de-cartier-22mm-small-stainless-steel-and-diamond-watch/19971654707113799",
    image:
      "https://www.net-a-porter.com/variants/images/19971654707113799/in/w2000_a3-4_q80.jpg",
    title: "CARTIER Panthère de Cartier watch",
    price: 8400,
  },
  {
    productUrl:
      "https://www.saksfifthavenue.com/product/toteme-signature-stripe-turtleneck-sweater-0400013766200.html",
    image:
      "https://cdn.saksfifthavenue.com/is/image/saks/0400013766200_BLACKSTRIPE",
    title: "Toteme Signature Stripe Turtleneck Sweater",
    price: 570,
  },
];
