import { ApolloClient } from "@apollo/client";

/**
 * Utility function to reset the Apollo cache for specific queries.
 *
 * @param client - Apollo Client instance.
 * @param queryKey - The key of the query you want to reset from the cache.
 */
export const resetCacheForQuery = (
  // TODO: investigate if we want to set `any` here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  queryKey: string
) => {
  try {
    client.cache.evict({ id: "ROOT_QUERY", fieldName: queryKey });
    client.cache.gc(); // Garbage collect to ensure memory is freed.
  } catch (error) {
    console.error(`Error resetting cache for query: ${queryKey}`, error);
  }
};
