import { localStorageKeys } from "@/constants";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "@phiaco/phia-ui/dist/util";

export const getRecentSearches = (): string[] => {
  return getLocalStorageValue(localStorageKeys.RECENT_SEARCH, []);
};

export const setRecentSearches = (newValues: string[]) => {
  try {
    setLocalStorageValue(localStorageKeys.RECENT_SEARCH, newValues);
  } catch (e) {
    // If too many searches, this will throw. Keep 6 most recent
    const refreshedValues = newValues.slice(0, 6);
    setLocalStorageValue(localStorageKeys.RECENT_SEARCH, refreshedValues);
  }
};

export const addRecentSearch = (value: string) => {
  const recentSearches = getRecentSearches();
  const updated = [value, ...recentSearches.filter(prev => prev !== value)];
  setRecentSearches(updated);
};

export const removeRecentSearch = (value: string) => {
  const recentSearches = getLocalStorageValue(
    localStorageKeys.RECENT_SEARCH,
    []
  );
  const updated = [...recentSearches].filter(t => t !== value);
  setRecentSearches(updated);
};

export const isValidImageUrl = (url: string): Promise<boolean> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};
